import React, { useState, useMemo, useRef, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  Stack,
  Typography,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  DialogContent,
  FormControl,
  Box,
} from '@mui/material'
import { useQuery, gql, NetworkStatus } from '@apollo/client'
import { z } from 'zod'

import {
  Button,
  Dialog,
  Select,
  InputLabel,
  Input,
  ServiceIcon,
  DatePicker,
} from '@jeeves/new-components'
import { graphql } from '@jeeves/graphql'
import { useRepoTypeSelectOptions } from '@jeeves/hooks'

import useRequestAccess from './useRequestAccess'
import useQueryParams from './useQueryParams'
import { RequestAccessFeedbackDialog } from './RequestAccessFeedbackDialog'
import LoadingState from './LoadingState'
import ErrorState from './ErrorState'
import UnableToRequestAccess from './UnableToRequestAccess'
import RepositoryAutoComplete from './RepositoryAutocomplete'

const CAN_REQUEST_ACCESS = graphql(`
  query CanRequestAccess($first: Int, $accessibility: ReposAccessibilityState!) {
    user {
      ... on ControlPlaneUser {
        id
        repos(first: $first, accessibility: $accessibility) {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`)

const ACCESS_PORTAL_REQUESTABLE_REPOS = gql`
  query AccessPortalRequestableRepos(
    $first: Int
    $after: String
    $filters: IdentityReposFilters
    $accessibility: ReposAccessibilityState!
  ) {
    user {
      ... on ControlPlaneUser {
        id
        repos(first: $first, after: $after, filters: $filters, accessibility: $accessibility) {
          edges {
            node {
              id
              name
              type
              tags
              config {
                authentication {
                  allowNativeAuthentication
                  otpDbuser
                }
                TLS {
                  enableClientSidecarTLS
                }
              }
              ... on MongoDBReplicaSetRepo {
                replicaSetName
              }
            }
            accessibleUserAccounts {
              edges {
                node {
                  id
                  name
                }
              }
            }
            accessPortalBindingRelationship {
              edge {
                node {
                  id
                  ... on ClusterBinding {
                    boundListenersRelationship {
                      edges {
                        node {
                          id
                          port
                        }
                      }
                    }
                  }
                  ... on S3Binding {
                    listenerSet {
                      proxyListener {
                        id
                        port
                      }
                      browserListener {
                        id
                        port
                      }
                    }
                  }
                  ... on SingleListenerBinding {
                    listener {
                      id
                      port
                    }
                  }
                }
                sidecar {
                  name
                  endpoint
                  userEndpoint
                }
              }
            }
          }
          ...RepositoryAutocomplete_ControlPlaneUserRepos_ConnectionFragment
        }
      }
    }
  }
`

export const RequestAccessDialog = () => {
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const history = useHistory()
  const { path } = useRouteMatch()
  const { requestAccess, loading: requestLoading } = useRequestAccess()
  const { queryParams, setQueryParam, removeQueryParam } = useQueryParams()
  const requestAccessRouteMatch = useRouteMatch(`${path}/request-access`)
  const { options } = useRepoTypeSelectOptions()
  const repoTypeSelectOptions = options.filter(option => option.value !== 'snowflake')

  const paramsSchema = z.object({
    repositoryType: z.enum(repoTypeSelectOptions.map(option => option.value)).catch(''),
    repositoryName: z.string().catch(''),
    databaseAccount: z.string().catch(''),
    validUntil: z.string().datetime().catch(''),
    reason: z.string().catch(''),
  })

  const parsedQueryParams = paramsSchema.parse({
    repositoryType: queryParams.get('repositoryType'),
    repositoryName: queryParams.get('repositoryName'),
    databaseAccount: queryParams.get('databaseAccount'),
    validUntil: queryParams.get('validUntil'),
    reason: queryParams.get('reason'),
  })

  const [searchValue, setSearchValue] = useState(parsedQueryParams.repositoryName)
  const [requestReason, setRequestReason] = useState(parsedQueryParams.reason)

  const canRequestAccessQuery = useQuery(CAN_REQUEST_ACCESS, {
    variables: {
      accessibility: 'ALL',
      first: 10,
    },
  })

  const {
    data,
    loading,
    error: requestableReposError,
    fetchMore,
    networkStatus,
  } = useQuery(ACCESS_PORTAL_REQUESTABLE_REPOS, {
    variables: {
      accessibility: 'ALL',
      first: 10,
      filters: {
        ...(parsedQueryParams.repositoryType && { repoTypes: [parsedQueryParams.repositoryType] }),
        repoName: searchValue,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    // This useEffect is used to clear the searchValue when the `repositoryName` query parameter resulted in no results.
    // This is mainly to improve the UX because if the `searchValue` is not cleared, then it appears as if there are no
    // repos in the "Repository Name" dropdown, even though there are.

    const searchValueMatchesRepoParam = searchValue === parsedQueryParams.repositoryName
    const noResults = data?.user?.repos?.edges?.length === 0
    const shouldClearSearchValue = searchValueMatchesRepoParam && noResults

    if (shouldClearSearchValue) {
      setSearchValue('')
    }
  }, [data?.user?.repos?.edges?.length, parsedQueryParams.repositoryName, searchValue])

  const autocompleteOptions = useMemo(() => {
    const edges = networkStatus === NetworkStatus.setVariables ? [] : data?.user?.repos?.edges ?? []

    return edges.map(repoEdge => ({
      icon: <ServiceIcon type={repoEdge.node.type} />,
      label: repoEdge.node.name,
      id: repoEdge.node.id,
      repoEdge,
    }))
  }, [data?.user?.repos?.edges, networkStatus])

  const selectedRepoRef = useRef()

  const selectedRepo = useMemo(() => {
    if (!parsedQueryParams.repositoryName) {
      return null
    }

    const newSelectedRepo = autocompleteOptions.find(
      option => option.label === parsedQueryParams.repositoryName
    )

    if (newSelectedRepo) {
      selectedRepoRef.current = newSelectedRepo
    }

    return selectedRepoRef.current
  }, [parsedQueryParams.repositoryName, autocompleteOptions])

  const accessibleUserAccountEdges = selectedRepo?.repoEdge.accessibleUserAccounts.edges ?? []

  const selectedUserAccount = accessibleUserAccountEdges.find(
    userAccountEdge => userAccountEdge.node.name === parsedQueryParams.databaseAccount
  )

  const handleOnClose = () => {
    setSearchValue('')
    setRequestReason('')
    history.push('/access-portal')
  }
  const handleOnFeedbackClose = () => {
    handleOnClose()
    setFeedbackOpen(false)
  }

  const onSubmit = async e => {
    e.preventDefault()

    try {
      await requestAccess({
        variables: {
          repoId: selectedRepo.id,
          request: {
            validFrom: new Date().toISOString(),
            userAccountId: selectedUserAccount.node.id,
            comments: requestReason,
            validUntil: parsedQueryParams.validUntil || null,
          },
        },
      })
      setFeedbackOpen(true)
    } catch (e) {
      console.error(e)
    }
  }

  const initialLoading = networkStatus === NetworkStatus.loading || canRequestAccessQuery.loading
  const error = requestableReposError || canRequestAccessQuery.error

  const repoEdgesUserCanRequest = canRequestAccessQuery.data?.user?.repos?.edges ?? []

  const canRequestAccess = repoEdgesUserCanRequest?.length > 0

  return (
    <React.Fragment>
      <Dialog
        open={Boolean(requestAccessRouteMatch)}
        onClose={handleOnClose}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            position: 'absolute',
            right: 0,
            margin: 0,
            top: 0,
            height: '100vh',
            minHeight: '100vh',
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            Request Access
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack
            sx={{ justifyContent: 'space-between', height: '100%' }}
            component="form"
            onSubmit={onSubmit}
          >
            <Stack spacing={4}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Request access to a data repository.
              </Typography>

              {initialLoading ? (
                <LoadingState />
              ) : error ? (
                <ErrorState />
              ) : !canRequestAccess ? (
                <UnableToRequestAccess />
              ) : (
                <Stack spacing={3}>
                  <Box>
                    <FormControl variant="standard" sx={{ display: 'flex' }}>
                      <InputLabel id="repoType-select-label" htmlFor="repoType-select">
                        Repository Type
                      </InputLabel>

                      <Select
                        labelId="repoType-select-label"
                        id="repoType-select"
                        onChange={e => {
                          setSearchValue('')

                          removeQueryParam('repositoryName')
                          removeQueryParam('databaseAccount')
                          setQueryParam('repositoryType', e.target.value)
                        }}
                        options={repoTypeSelectOptions}
                        value={parsedQueryParams.repositoryType}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <RepositoryAutoComplete
                      setSearchValue={setSearchValue}
                      networkStatus={networkStatus}
                      loading={loading}
                      fetchMore={fetchMore}
                      options={autocompleteOptions}
                      selectedRepo={selectedRepo}
                      controlPlaneUserRepos_Connection={data?.user?.repos}
                    />
                  </Box>
                  <Box>
                    <FormControl
                      variant="standard"
                      sx={{ display: 'flex' }}
                      disabled={!selectedRepo}
                    >
                      <InputLabel id="dbAccount-select-label" htmlFor="dbAccount-select" required>
                        Database Account
                      </InputLabel>

                      <Select
                        labelId="dbAccount-select-label"
                        id="dbAccount-select"
                        options={
                          accessibleUserAccountEdges.map(userAccountEdge => ({
                            label: userAccountEdge.node.name,
                            value: userAccountEdge.node.id,
                          })) ?? []
                        }
                        onChange={e => {
                          const userAccountEdge = accessibleUserAccountEdges.find(
                            userAccountEdge => userAccountEdge.node.id === e.target.value
                          )

                          setQueryParam('databaseAccount', userAccountEdge.node.name)
                        }}
                        value={selectedUserAccount?.node.id ?? ''}
                      />
                    </FormControl>
                  </Box>

                  <Stack spacing={1}>
                    <Box>
                      <FormControl component="fieldset" variant="standard">
                        <InputLabel component="legend" required>
                          How long do you need access?
                        </InputLabel>
                        <RadioGroup
                          onChange={e => {
                            if (e.target.value === 'always') {
                              removeQueryParam('validUntil')
                            } else {
                              setQueryParam('validUntil', new Date().toISOString())
                            }
                          }}
                          value={parsedQueryParams.validUntil ? 'until' : 'always'}
                          row
                          aria-label="duration"
                          sx={{
                            'legend + &': {
                              marginTop: 3,
                            },
                            gap: 8,
                          }}
                        >
                          <FormControlLabel
                            value="always"
                            control={<Radio size="small" disableRipple />}
                            label="Always"
                          />
                          <FormControlLabel
                            value="until"
                            control={<Radio size="small" disableRipple />}
                            label="Until:"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    {parsedQueryParams.validUntil && (
                      <DatePicker
                        onChange={val => setQueryParam('validUntil', val)}
                        value={parsedQueryParams.validUntil}
                        sx={{
                          flex: 1,
                        }}
                        selectTime
                      />
                    )}
                  </Stack>

                  <Box>
                    <FormControl component="fieldset" variant="standard" sx={{ display: 'flex' }}>
                      <InputLabel
                        sx={{
                          typography: 'h6',
                        }}
                        shrink
                        disableAnimation
                      >
                        Reason
                      </InputLabel>
                      <Input
                        id="description-input"
                        value={requestReason}
                        onChange={e => setRequestReason(e.target.value)}
                        multiline
                        rows={5}
                        placeholder="e.g. Approval for contract period."
                      />
                    </FormControl>
                  </Box>
                </Stack>
              )}
            </Stack>

            <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
              <Button variant="text" onClick={handleOnClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                loading={requestLoading}
                disabled={!selectedRepo || !selectedUserAccount}
              >
                Request Access
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <RequestAccessFeedbackDialog open={feedbackOpen} handleOnClose={handleOnFeedbackClose} />
    </React.Fragment>
  )
}
